<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "lingXiaoCheng",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(凌霄城场景可能会失败,回到苏州初始环境,如果失败看菜单初始环境教程)(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },

                    {
                        title: "功能说明",
                        text:[
                            "#切换到凌霄城页",
                            "#按图1说明参数:",
                            "1.必填项-技能:打BOSS的输出技能,建议用最高输出套路,用远程套路",
                            '2.必填项-指定刷x个人后:填写刷几个boss,可以填1-5数字,最大是5,这个活动一共6个boss,如果全部刷就清空,什么都不填!',
                            '#默认是8个门派一次性刷完,如果中断了或者已经打了几个门派不影响,回到凌霄城武师附近开始.',
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#角色移动到凌霄城活动NPC旁边(图1)",
                            "#注意识别范围和图中说明(图2)",
                            "#把打坐技能放到快捷栏上,每次打完BOSS自动打坐",
                            "#以上设置好,角色移动到凌霄城活动NPC旁边,开始任务",
                        ],
                        img:[
                            "2.png",
                            "3.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
